/* eslint-disable react/prop-types */
import React from 'react';
import { Helmet } from 'react-helmet';

const SEO = ({ seo }) => (
	<Helmet title={seo.title}>
		<meta name="description" content={seo.metaDesc} />
		<meta name="image" content={seo.opengraphImage.localFile.url} />
		{seo.opengraphUrl && <meta property="og:url" content={seo.opengraphUrl} />}
		{(seo.opengraphType ? true : null) && <meta property="og:type" content="article" />}
		{seo.title && <meta property="og:title" content={seo.opengraphTitle} />}
		{seo.opengraphDescription && <meta property="og:description" content={seo.opengraphDescription} />}
		{seo.opengraphImage && <meta property="og:image" content={seo.opengraphImage.localFile.url} />}
		<meta name="twitter:card" content="summary_large_image" />
		{seo.twitterUsername && <meta name="twitter:creator" content={seo.twitterUsername} />}
		{seo.title && <meta name="twitter:title" content={seo.title} />}
		{seo.description && <meta name="twitter:description" content={seo.description} />}
		{seo.twitterImage && <meta name="twitter:image" content={seo.twitterImage.localFile.url} />}
		<link
			href="https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap"
			rel="stylesheet"
		/>
	</Helmet>
);
export default SEO;
